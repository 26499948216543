<template>
  <v-card>
    <v-card-title> Moo 2022 </v-card-title>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
          <v-autocomplete
            :items="states"
            item-text="_id"
            item-value="_id"
            v-model="searchCriteria.states"
            clearable
            autocomplete
            multiple
            label="States"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6">
          <v-autocomplete
            :items="gender"
            item-text="_id"
            item-value="_id"
            clearable
            v-model="searchCriteria.gender"
            autocomplete
            multiple
            label="Gender"
          ></v-autocomplete>
        </v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="ageRanges"
              label="Age Range"
              item-text="id"
              item-value="value"
              chip
              clearable
              deletable-chips
              persistent-hint
              v-model="searchCriteria.ageGroup"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <v-menu
              ref="startDateMenu"
              v-model="startDateMenu"
              :close-on-content-click="false"
              :return-value.sync="month"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="searchCriteria.month"
                  label="Select Month"
                  prepend-icon="event"
  
                  readonly
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
               <v-date-picker v-model="searchCriteria.month" type="month">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$refs.startDateMenu.save(Date(month))">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>


      </v-row>
      <v-btn @click="filter()" color="primary" class="mr-4 mt-5 mb-5">Filter</v-btn>
    
      <h4>Total Count  {{users.length}}</h4> 

    <v-data-table
      :headers="headers"
      :items="users"
      item-key="userId"
      class="elevation-1"
      no-data-text="No users"
    >
    </v-data-table>

    </v-container>
  </v-card>
</template>

<script>
import RestResource from "../../services/dataServiceMobile";
const service = new RestResource();

export default {
  data() {
    return {
      searchCriteria: {},
      states: [],
      gender: [],
      startDateMenu: false,
      month: null,
      users: [],
      ageRanges: [
        { id: "18 - 25" },
        { id: "26 - 35" },
        { id: "36 - 45" },
        { id: "46 - 55" }
      ],
      headers: [
        { text: "UserId", align: "left", sortable: true, value: "userId" },
        { text: "ProjectId", value: "projectId", sortable: true },
         { text: "Activities", value: "submissions", sortable: true },
        { text: "Status", value: "status", sortable: true },
      ]
    };
  },

  methods: {
    fetchStates() {
      this.$setLoader();

      service.getStates().then((r) => {
        this.states = r.data.states;
        this.$disableLoader();
      });
    },

    fetchGender() {
      this.$setLoader();

      service.getGender().then((r) => {
        this.gender = r.data.gender;
        this.$disableLoader();
      });
    },

    filter() {
      this.$setLoader()
      service.filterData(this.searchCriteria).then((res) => {
        // eslint-disable-next-line
        console.log(res);
        this.users = res.data.data
        this.$disableLoader()
      });
    },
  },

  mounted() {
    this.fetchStates();
    this.fetchGender();
  },
};
</script>

<style scoped>
#bt1 {
  float: right;
}
</style>
